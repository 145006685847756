import React, { useState, useEffect } from 'react';
import AppCard from '../components/AppCard';
import Communication from '../data/categories/Communication.json';
import Maps from '../data/categories/Maps_Navigation.json';
import Business from '../data/categories/Business.json';
import Photography from '../data/categories/Photography.json';
import Entertainment from '../data/categories/Entertainment.json';
import MusicAudio from '../data/categories/Music_Audio.json';
import './EditorsChoice.css';
import { Helmet } from 'react-helmet';

function EditorsChoice({ limit, showTitle = true }) {
  const [sortedApps, setSortedApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // 处理 JSON 数据的辅助函数
  const extractAppsFromJson = (jsonData, category, limit = 5) => {
    try {
      if (jsonData?.result?.[0]?.items) {
        return jsonData.result[0].items
          .slice(0, limit)
          .map(item => ({
            id: item.app_id,
            name: item.item.title,
            description: item.item.description,
            icon: item.item.icon,
            rating: item.item.rating?.value || 0,
            downloads: item.item.installs || "0+",
            category: category,
            developer: item.item.developer,
            images: item.item.images || []
          }));
      }
      return [];
    } catch (error) {
      console.error(`Error extracting ${category} apps:`, error);
      return [];
    }
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      
      // 从每个类别提取前5个应用
      const mapsApps = extractAppsFromJson(Maps, 'Maps');
      const businessApps = extractAppsFromJson(Business, 'Business');
      const photographyApps = extractAppsFromJson(Photography, 'Photography');
      const entertainmentApps = extractAppsFromJson(Entertainment, 'Entertainment');
      const musicAudioApps = extractAppsFromJson(MusicAudio, 'Music & Audio');
      const communicationApps = extractAppsFromJson(Communication, 'Communication');

      // 合并所有应用
      const allApps = [
        ...mapsApps,
        ...businessApps,
        ...photographyApps,
        ...entertainmentApps,
        ...musicAudioApps,
        ...communicationApps
      ];

      // 如果设置了 limit，则只取前 limit 个应用
      setSortedApps(limit ? allApps.slice(0, limit) : allApps);
    } catch (error) {
      console.error('Error loading apps:', error);
      setSortedApps([]);
    } finally {
      setIsLoading(false);
    }
  }, [limit]);

  return (
    <div className="editors-choice-page">
      <Helmet>
        <title>AppFinder - Editors' Choice Apps</title>
      </Helmet>
      <div className="editors-choice-container">
        {showTitle && <h1 className="page-title">- Editors' Choice</h1>}
        <div className="apps-grid">
          {isLoading ? (
            <div className="loading">加载中...</div>
          ) : sortedApps.length > 0 ? (
            sortedApps.map((app) => (
              <AppCard key={app.id} app={app} />
            ))
          ) : (
            <div className="no-apps-message">No apps found</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditorsChoice; 