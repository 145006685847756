import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AppCard from '../components/AppCard';
import { categoryData } from '../data/index';

import './CategoryPage.css';

function CategoryPage() {
  const { category } = useParams();
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // 使用从index.js导入的categoryData
  const categoryNames = {
    'communication': 'Communication',
    'video-players': 'Video Players & Editors',
    'personalization': 'Personalization',
    'maps': 'Maps & Navigation',
    'business': 'Business',
    'travel': 'Travel & Local',
    'productivity': 'Productivity',
    'comics': 'Comics',
    'beauty': 'Beauty',
    'adventure': 'Adventure',
    'action': 'Action',
    'arcade': 'Arcade',
    'board': 'Board',
    'dating': 'Dating',
    'role-playing': 'Role Playing',
    'social': 'Social',
    'finance': 'Finance',
    'tools': 'Tools',
    'lifestyle': 'Lifestyle',
    'weather': 'Weather',
    'education': 'Education',
    'art-design': 'Art & Design',
    'parenting': 'Parenting',
    'house-home': 'House & Home',
    'libraries-demo': 'Libraries & Demo',
    'puzzle': 'Puzzle',
    'card': 'Card',
    'strategy': 'Strategy',
    'trivia': 'Trivia',
    'sports': 'Sports',
    'educational': 'Educational',
    'shopping': 'Shopping',
    'food-drink': 'Food & Drink',
    'photography': 'Photography',
    'music-audio': 'Music & Audio',
    'news-magazines': 'News & Magazines',
    'health-fitness': 'Health & Fitness',
    'books-reference': 'Books & Reference',
    'medical': 'Medical',
    'auto-vehicles': 'Auto & Vehicles',
    'casual': 'Casual',
    'simulation': 'Simulation',
    'word': 'Word',
    'events': 'Events',
    'racing': 'Racing',
    'entertainment': 'Entertainment'
  };

  useEffect(() => {
    setIsLoading(true);
    const loadApps = () => {
      const categoryJson = categoryData[category];
      if (categoryJson?.result?.[0]?.items) {
        const processedApps = categoryJson.result[0].items.map(item => ({
          id: item.app_id,
          name: item.item.title,
          icon: item.item.icon,
          rating: item.item.rating?.value || 0,
          downloads: item.item.installs || "0+",
          developer: item.item.developer
        }));
        setApps(processedApps);
      } else {
        setApps([]);
      }
      setIsLoading(false);
    };

    loadApps();
  }, [category]);

  if (!categoryData[category]) {
    return <div className="category-not-found">Not Found</div>;
  }

  const getCategoryDisplayName = () => {
    return category
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="category-page">
      <Helmet>
        <title>{`${getCategoryDisplayName()} Apps - ProAppFinder`}</title>
        <meta name="description" content={`Download the best ${getCategoryDisplayName()} apps for Android. Browse through our curated collection of ${getCategoryDisplayName().toLowerCase()} applications.`} />
        <meta name="keywords" content={`${getCategoryDisplayName().toLowerCase()}, apps, android apps, download apps, ${category} applications, mobile apps`} />
        <meta property="og:title" content={`${getCategoryDisplayName()} Apps - ProAppFinder`} />
        <meta property="og:description" content={`Discover and download the best ${getCategoryDisplayName()} apps for your Android device.`} />
        <link rel="canonical" href={`https://www.proappfinder.com/category/${category}/index.html`} />
      </Helmet>
      <h1 className="page-title">- {getCategoryDisplayName()}</h1>
      <div className="apps-grid">
        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : apps.length > 0 ? (
          apps.map(app => (
            <AppCard key={app.id} app={app} />
          ))
        ) : (
          <div className="no-apps">No apps found</div>
        )}
      </div>
    </div>
  );
}

export default CategoryPage;